/**
 * Message with detailed information based on the data  it receives
 *
 * @module views/components/BlockMessage
 * @memberof -Common
 */
import './BlockMessage.scss';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import List from '@ulta/core/components/List/List';
import Text from '@ulta/core/components/Text/Text';
import useLoader from '@ulta/core/hooks/useLoader/useLoader';
import { useToggle } from '@ulta/core/hooks/useToggle/useToggle';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';

import ActionGroup from '@ulta/modules/ActionGroup/ActionGroup';

import { moveScreenReaderFocus } from '@ulta/utils/accessibility/accessibility';

import BagItemThumbNail from '../BagItemThumbNail/BagItemThumbNail';

/**
 * Represents a BlockMessage component
 *
 * @method
 * @param {BlockMessageProps} props - React properties passed from composition
 * @returns BlockMessage
 */
export const BlockMessage = function( props ){
  const {
    actionIcon,
    componentKey,
    dismissAction,
    invokeMutation,
    items,
    loading,
    messageType,
    shouldFocusOnMount,
    showCloseButton
  } = props;
  const messageIcon = getIcon( { messageType, actionIcon } );

  const [loader, showLoader, hideLoader] = useLoader( {} );
  const [hideBlock, setHide] = useToggle( false );

  const [visibleProducts, setVisibleProducts] = useState( [] );
  const [closeButtonClicked, setCloseButtonClicked] = useState( false );

  const { breakpoint } = useDeviceInflection();
  const hasMoreItems = items?.length > visibleProducts.length;

  useEffect( () => {
    if( items?.length > 0 ){
      if( breakpoint?.CURRENT_BREAKPOINT === 'SM' ){
        setVisibleProducts( items.slice( 0, 2 ) );
      }
      else {
        setVisibleProducts( items.slice( 0, 4 ) );
      }
    }
  }, [items, breakpoint] );

  useEffect( () => {
    handleHideLoader( { loading, closeButtonClicked }, { hideLoader } );
  }, [loading] );

  const ref = useRef( null );
  useEffect( () => {
    if( shouldFocusOnMount ){
      moveScreenReaderFocus( ref.current );
    }
  }, [] );

  if( !props?.message ){
    return null;
  }

  return (
    <div
      { ...( props.ariaLiveType && { 'aria-live': props.ariaLiveType } ) }
      { ...( props.role && { role: props.role } ) }
      className={ classNames( 'BlockMessage', {
        'BlockMessage--hide': hideBlock,
        [`BlockMessage--${props.backgroundColor}`]: props.backgroundColor,
        [`BlockMessage--${props.borderColor}`]: props.borderColor
      } ) }
    >
      { loader }
      { showCloseButton &&
        <Button
          icon
          ariaLabel={ dismissAction?.label }
          iconImage='X'
          iconSize='lg'
          variant='unstyled'
          className='BlockMessage__close'
          action={ dismissAction }
          onClick={ () =>
            handleClose(
              { dismissAction },
              { invokeMutation, setCloseButtonClicked, setHide, showLoader }
            )
          }
        />
      }
      { messageIcon && (
        <div className='BlockMessage__icon'>
          <Icon
            size='m'
            name={ messageIcon }
            aria-hidden={ true }
            className={ `BlockMessage__icon--${props.borderColor}` }
          />
        </div>
      ) }
      <div className='BlockMessage__message'
        ref={ ref }
        { ...( props.shouldFocusOnMount && { 'tabindex': -1 } ) }
      >
        <Text
          textStyle='body-2'
          htmlTag='p'
        >
          { props.message }
        </Text>
        { props.description && (
          <div className='BlockMessage__description'>
            <Text textStyle='body-2'
              htmlTag='span'
            >
              { props.description.label }
            </Text>
            <ul className='BlockMessage__description--list'>
              <li className='BlockMessage__description--listContent'>{ props.description.info }</li>
            </ul>
          </div>
        ) }
        { ( props.action1 || props.action2 ) && (
          <div className='BlockMessage__actionGroup'>
            <ActionGroup
              action1={ props.action1 }
              action2={ props.action2 }
              action1Style={ props.action1Style }
              action2Style={ props.action2Style }
            />
          </div>
        ) }
        { props.messageAction && (
          <Button
            label={ props.messageAction?.label }
            variant='link'
            iconImage='ArrowForward'
            iconSize='s'
            iconRight={ true }
            action={ { ...props.messageAction, config: {} } }
            ariaHiddenIcon={ true }
            alignment='right'
            crossButtonVisibility={ true }
            className='BlockMessage__messageAction'
          />
        ) }
        { visibleProducts?.length > 0 && (
          <div className='BlockMessage__ItemThumbnailList'>
            <List display='Inline'
              spacer='00'
            >
              { visibleProducts?.map( ( item, index ) => (
                <div className='BlockMessage__ItemThumbnailSpace'
                  key={ `${componentKey}:${index}:item-tn` }
                >
                  <BagItemThumbNail
                    image={ { ...item.image, width: 48 } }
                    quantity={ item.quantity > 1 && item.quantity }
                    thumbSize='md'
                    showBackground={ false }
                  />
                </div>
              ) ) }
            </List>
            { hasMoreItems && (
              <BagItemThumbNail
                thumbSize='sm'
                quantityLabel={ `+${items.length - visibleProducts.length}` }
                showBackground={ false }
              />
            ) }
          </div>
        ) }
      </div>
    </div>
  );
};

const MESSAGE_ICON_MAPPING = {
  error: 'ExclamationTriangle',
  warning: 'ExclamationTriangle',
  info: 'InfoCircle',
  success: 'Check'
};

/**
 * Returns icon based on the messageType
 *
 * @method
 * @param {object} data - message type of BlockMessage
 * @returns getIcon
 */
export const getIcon = ( data ) => {
  if( !data ){
    return undefined;
  }
  const { messageType, actionIcon } = data;
  const icon = ( messageType ) || actionIcon;
  return MESSAGE_ICON_MAPPING[icon];
};

/**
 * Handles close action
 *
 * @method
 * @param {object} data - required data to be used are passed
 * @param {object} methods - required methods to be used are passed
 */
export const handleClose = ( data, methods ) => {
  const { dismissAction } = data;
  const { invokeMutation, setCloseButtonClicked, setHide, showLoader } = methods;
  setCloseButtonClicked( true );
  if( dismissAction?.graphql ){
    showLoader();
    invokeMutation( {
      graphql: dismissAction.graphql,
      customHeaders: dismissAction.customHeaders,
      variables: {
        moduleParams: {},
        url: { path: !isServer() && global.location.pathname }
      }
    } );
  }
  else {
    setHide( true );
  }
};

/**
 * Handles loader hiding
 *
 * @method
 * @param {object} data - required data to be used are passed
 * @param {object} methods - required methods to be used are passed
 */
export const handleHideLoader = ( data, methods ) => {
  const { loading, closeButtonClicked } = data;
  const { hideLoader } = methods;
  closeButtonClicked && !loading && hideLoader();
};

/**
 * Property type definitions
 * @typedef BlockMessageProps
 * @type {object}
 * @property {string} message - content to display
 * @property {string} spacerBetween - margin-bottom in between messages
 * @property {boolean} showCloseButton - for show/hide the close icon
 * @property {string} action1 - Set the action1 for the Message
 * @property {string} action2 - Sets the action2 for the Message
 * @property {string} action1Style - Set the action1Style
 * @property {string} action2Style - Set the action2Style
 * @property {string} description - for showing the list info
 * @property {string} icon - type of the icon
 * @property {string} backgroundColor - type of the backgroundColor
 * @property {string} borderColor - type of the borderColor
 * @property {object} messageAction - sets the link
 * @property {string} image - sets the image url
 * @property {string} quantity - sets the quantity of the products
 * @property {object} dismissAction - sets the close button action
 * @property {string} dismissAccessibilityLabel - ariaLabel for the close button
 * @property {boolean} shouldFocusOnMount - When true, sets user focus after mounting component
 */
export const propTypes = {
  message: PropTypes.string,
  showCloseButton: PropTypes.bool,
  action1: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  action2: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  action1Style: PropTypes.string,
  action2Style: PropTypes.string,
  description: PropTypes.shape( {
    label: PropTypes.string,
    info: PropTypes.string
  } ),
  icon: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape( {
      image: PropTypes.shape( {
        imageUrl: PropTypes.string,
        altText: PropTypes.string
      } ),
      quantity: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] )
    } )
  ),
  messageAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  ariaLiveType: PropTypes.oneOf( ['polite', 'assertive'] ),
  role: PropTypes.oneOf( ['alert', 'status'] ),
  dismissAction: PropTypes.shape( {
    label: PropTypes.string,
    graphql: PropTypes.string
  } ),
  dismissAccessibilityLabel: PropTypes.string,
  shouldFocusOnMount: PropTypes.bool
};

/**
 * Default values for passed properties
 * @type object
 * @property { boolean } [ showCloseButton = true ] - show the close button by default
 */
export const defaultProps =  {
  showCloseButton: true
};

BlockMessage.propTypes = propTypes;
BlockMessage.defaultProps = defaultProps;

export default BlockMessage;
